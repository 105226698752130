import React, { useEffect, useState, Fragment } from "react";
import { ThemedButton } from "../../components/buttons/themedButton";
import { postDownloadRequest, postJson } from "../../utils/api-client";
import {
  ChatAltIcon,
  CheckIcon,
  DocumentIcon,
  PaperClipIcon,
  UserCircleIcon,
} from "@heroicons/react/solid";
import * as Yup from "yup";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { classNames, toastError, toastSuccess } from "../../utils/utils";
import { DocumentTextIcon } from "@heroicons/react/outline";

function AdditionalInformation(props) {
  const { data } = props;
  return (
    <div className="detailsSection">
      <div className="px-4 py-4 sm:px-4">
        <h2 className="detailsHeader">Additional Information</h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div className="text-sm font-medium text-gray-500">Request Title</div>
        <div className="mt-1 text-sm text-gray-900">{data.title}</div>
        <div className="mt-8">
          <div className="text-sm font-medium text-gray-500">
            Request Description
          </div>
          <div className="mt-1 text-sm text-gray-900">{data.message}</div>
        </div>
      </div>
    </div>
  );
}

function DocumentRequestDetails(props) {
  const {
    docRequest,
    onDownloadDocument,
    onDeclineDocument,
    onAcceptDocument,
  } = props;

  return (
    <tr key={docRequest.key}>
      <td className="align-top">
        <span className="text-gray-600 text-sm font-semibold">
          {docRequest.name}
        </span>
      </td>
      <td>
        {docRequest.docFiles.map(
          (docFile) =>
            docFile.status !== "Received" && (
              <div
                className="flex-1 flex items-center w-auto group h-5"
                key={docFile.key}
              >
                <DocumentTextIcon className="w-5 text-gray-400 mr-1" />
                {!docFile.purged ? (
                  <div
                    onClick={() => onDownloadDocument(docFile)}
                    className={`text-sm text-blue-600 hover:text-blue-500 w-full cursor-pointer ${
                      docFile.docFileStatus === "Declined" ? "line-through" : ""
                    }`}
                  >
                    {docFile.filename}
                  </div>
                ) : (
                  <div className="text-sm text-gray-500 w-full">
                    {docFile.filename} (Purged)
                  </div>
                )}
              </div>
            )
        )}
      </td>
    </tr>
  );
}

function DocumentRequests(props) {
  const { customerRequestKey, data } = props;

  const [documentRequests, setDocumentRequests] = useState(null);

  const handleDownloadDocument = (docFile) => {
    postDownloadRequest(`/api/docfile/${docFile.key}/download`);
  };

  const handleDeclineDocument = async (docRequestKey, docFileKey) => {
    const url = `/api/docfile/${docFileKey}/decline`;
    const response = await postJson(url);

    if (response.ok) {
      toastSuccess("Document has been declined");
      const updates = documentRequests.map((docRequest) => {
        if (docRequest.key === docRequestKey) {
          return {
            ...docRequest,
            docFiles: docRequest.docFiles.map((docFile) => ({
              ...docFile,
              docFileStatus:
                docFile.key === docFileKey ? "Declined" : docFile.docFileStatus,
            })),
          };
        } else {
          return { ...docRequest };
        }
      });

      setDocumentRequests(updates);
    } else {
      toastError("Error in declinding document");
    }
  };

  const handleAcceptDocument = async (docRequestKey, docFileKey) => {
    const url = `/api/docfile/${docFileKey}/accept`;
    const response = await postJson(url);

    if (response.ok) {
      toastSuccess("Document has been accepted");
      const updates = documentRequests.map((docRequest) => {
        if (docRequest.key === docRequestKey) {
          return {
            ...docRequest,
            docFiles: docRequest.docFiles.map((docFile) => ({
              ...docFile,
              docFileStatus:
                docFile.key === docFileKey
                  ? "Submitted"
                  : docFile.docFileStatus,
            })),
          };
        } else {
          return { ...docRequest };
        }
      });

      setDocumentRequests(updates);
    } else {
      toastError("Error in accepting document");
    }
  };

  useEffect(() => {
    setDocumentRequests(data);
  }, [data]);

  return (
    <div className="detailsSection">
      <div className="px-4 py-4 sm:px-4 flex justify-between ">
        <h2 className="detailsHeader">Documents</h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <table className="dataTable w-full ">
          <thead>
            <tr>
              <th className="text-left">
                <span className="text-sm text-gray-500">
                  Requested Document
                </span>
              </th>
              <th className="text-left">
                <span className="text-sm text-gray-500">
                  Submitted Document
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {documentRequests?.map((docRequest) => (
              <DocumentRequestDetails
                key={docRequest.key}
                docRequest={docRequest}
                onDownloadDocument={handleDownloadDocument}
                onDeclineDocument={handleDeclineDocument}
                onAcceptDocument={handleAcceptDocument}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function RequestInformation(props) {
  const { data } = props;
  return (
    <div className="detailsSection">
      <div className="px-4 py-3 sm:px-4">
        <h2 className="detailsHeader">Request Information</h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:grid-cols-3">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Request Name</dt>
            <dd className="mt-1 text-sm text-gray-900">{data.requestName}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Account</dt>
            <dd className="mt-1 text-sm text-gray-900">{data.account}</dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

function RecipientInformations(props) {
  const { data } = props;

  return (
    <div className="detailsSection">
      <div className="px-4 py-3 sm:px-4">
        <h2 className="detailsHeader">Recipient Information</h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        {data.map((account, index) => (
          <dl
            key={account.key}
            className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:grid-cols-3"
          >
            <div className="sm:col-span-1">
              {index === 0 && (
                <dt className="text-sm font-medium text-gray-500">Recipient</dt>
              )}
              <dd className="mt-1 text-sm text-gray-900">
                {`${account.name} (${account.email})`}
              </dd>
            </div>
            <div className="sm:col-span-1">
              {index === 0 && (
                <dt className="text-sm font-medium text-gray-500">
                  Send Access Codes To
                </dt>
              )}
              <dd className="mt-1 text-sm text-gray-900">
                {!account.verificationPhoneNumber
                  ? account.email
                  : account.verificationPhoneNumber}
              </dd>
            </div>
          </dl>
        ))}
      </div>
    </div>
  );
}

export default function RequestDetails(props) {
  const { data } = props;

  const [recipientInformations, setRecipientInformations] = useState(null);
  const [documentRequests, setDocumentRequests] = useState(null);
  const [additionalInformation, setAdditionalInformation] = useState(null);
  const [messages, setMessages] = useState(null);
  const [status, setStatus] = useState("loading");

  useEffect(() => {
    setRecipientInformations(data.accounts);
    setDocumentRequests(data.docRequests);
    setAdditionalInformation({ title: data.title, message: data.message });
    setMessages(data.requestMessages);

    setStatus("loaded");
  }, [data]);

  return (
    status === "loaded" && (
      <>
        {data.status == "Canceled" && (
          <div class="mt-8 detailsSection">
            <CheckIcon className="text-green-600 w-6 mr-1 inline-block" />
            Closed
          </div>
        )}
        <div className="mt-8">
          <RequestInformation data={data} />
        </div>
        <div className="mt-8">
          <RecipientInformations data={recipientInformations} />
        </div>
        <div className="mt-8">
          <DocumentRequests
            customerRequestKey={data.key}
            data={documentRequests}
          />
        </div>
        <div className="mt-8">
          <div className="grid grid-cols-1 md:gap-5">
            <AdditionalInformation data={additionalInformation} />
          </div>
        </div>
      </>
    )
  );
}
