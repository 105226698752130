import React, { useState, useEffect } from "react";
import { authInfo, getAuthConfig } from "../components/authInfo";
import { getLoginDetails } from "../utils/utils";

const Auth0HistoryProvider = ({ children }) => {
  const [state, setState] = useState({ initialized: false });

  useEffect(() => {
    const loginDetails = getLoginDetails();
    const fetchAuthConfig = async (tenantKey) => {
      await getAuthConfig(tenantKey);
      setState({ initialized: true });
    };
    if (loginDetails?.tenantKey?.length > 0) {
      fetchAuthConfig(loginDetails.tenantKey);
    } else {
      setState({ initialized: true });
    }
  }, []);

  const { initialized } = state;

  return initialized ? <div>{children}</div> : <div>&nbsp;</div>;
};

export default Auth0HistoryProvider;
