import React, { createContext, useEffect, useState } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Placeholder } from "../../components/placeholder";
import { ErrorFallback } from "../../components/errorFallback";
import { RequestScreen } from "./requestScreen";
import { TenantRoute } from "../../components/tenantRoute";
import RequestListScreen from "./requestListScreen";
import { ProtectedRoute } from "../../components/protectedRoute";
import * as Constants from "../../constants";

export const OpenRequests = { key: "OpenRequests", name: "Open Requests" };
export const RequestWithSubmissions = {
  key: "RequestsWithSubmission",
  name: "Recent Submissions",
};
export const ClosedRequests = {
  key: "ClosedRequests",
  name: "Closed Requests",
};

export const RequestTabContext = createContext(null);

function RequestsIndex() {
  const match = useRouteMatch();

  const [state, setState] = useState({
    status: "loading",
  });

  const [requestsTab, setRequestsTab] = useState(OpenRequests);

  useEffect(() => {
    async function loadData() {
      const updates = {
        status: "loaded",
      };

      setState((prevState) => ({ ...prevState, ...updates }));
    }
    loadData();
  }, []);

  const [] = useState(OpenRequests);

  return (
    <RequestTabContext.Provider value={requestsTab}>
      <TenantRoute>
        {state.status === "loaded" ? (
          <Switch>
            <ProtectedRoute
              path={`${match.path}/:customerRequestKey`}
              requiredRoles={[Constants.ROLES_TENANT_VIEW]}
            >
              <RequestScreen />
            </ProtectedRoute>
            <ProtectedRoute
              path={match.path}
              requiredRoles={[Constants.ROLES_TENANT_VIEW]}
            >
              <RequestListScreen setRequestsTab={setRequestsTab} />
            </ProtectedRoute>
          </Switch>
        ) : state.status === "loading" ? (
          <Placeholder />
        ) : state.status === "error" ? (
          <div className="flex justify-center">
            <ErrorFallback error={state.error} />
          </div>
        ) : (
          ""
        )}
      </TenantRoute>
    </RequestTabContext.Provider>
  );
}

export { RequestsIndex };
