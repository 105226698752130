import React, { Fragment, useEffect, useState, useCallback } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { ThemedButton } from "../../components/buttons/themedButton";
import { ErrorModal } from "../../components/errorModal";
import { Placeholder } from "../../components/placeholder";
import { ErrorFallback } from "../../components/errorFallback";
import {
  CheckIcon,
  DotsVerticalIcon,
  RefreshIcon,
} from "@heroicons/react/solid";
import { DocuPointLayout } from "../../components/docupointLayout";
import BreadCrumb, {
  BreadCrumb_Requests_Request,
} from "../../components/breadcrumb";
import { Menu, Tab, Transition } from "@headlessui/react";
import Submissions from "./requestSubmissions";
import RequestDetails from "./requestDetails";
import { authInfo } from "../../components/authInfo";
import {
  postDownloadRequest,
  postJson,
  STATUS_CODE,
} from "../../utils/api-client";
import ConfirmModal from "../../components/confirmModal";
import { toastSuccess } from "../../utils/utils";
import { classNames } from "../../utils/utils";

const fetchRequestDetails = async (detailsId) => {
  const url = `api/customerrequests/${detailsId}`;
  const response = await fetch(url, {
    headers: authInfo.getAuthenticationHeaders(),
  });

  return response;
};

const useRequest = (detailsId) => {
  const [status, setStatus] = useState("loading");
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const getRequestDetails = useCallback(async () => {
    setStatus("loading");
    const response = await fetchRequestDetails(detailsId);

    if (response.status === STATUS_CODE.OK) {
      const responseData = await response.json();
      setData(responseData);
    } else if (response.status === STATUS_CODE.NOTFOUND) {
      setStatus("error");
      setError(`Sorry, request details for ${detailsId} does not exists`);
    } else {
      setStatus("error");
      setData(null);
      setError("Error in getting request details");
    }

    setStatus("loaded");
  }, [detailsId]);

  const refetch = () => {
    getRequestDetails();
  };

  useEffect(() => {
    getRequestDetails();
  }, [getRequestDetails]);

  return { status, error, data, refetch };
};

const screenTabs = [
  {
    key: 1,
    name: "Request",
  },
  {
    key: 2,
    name: "Submissions",
  },
];

function RequestScreen() {
  const history = useHistory();
  const { customerRequestKey } = useParams();
  const [error, setError] = useState(null);
  const {
    status,
    error: fetchRequestError,
    data,
    refetch,
  } = useRequest(customerRequestKey);
  const [requestDetails, setRequestDetails] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [hasDocuments, setHasDocuments] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const reload = () => {
    window.location.reload();
  };

  const closeRequest = async () => {
    try {
      await postJson(
        `api/customerrequests/${requestDetails.key}`,
        { ...requestDetails, status: "Canceled" },
        "PUT"
      );
    } catch (e) {
      setError("Error in closing request.");
      setShowErrorModal(true);
    }
    toastSuccess("This request is now canceled.");
    refetch();
  };

  const reopenRequest = async () => {
    try {
      await postJson(
        `api/customerrequests/${requestDetails.key}`,
        { status: "Pending" },
        "PUT"
      );
    } catch (e) {
      setError("Error in re-opening request.");
      setShowErrorModal(true);
    }
    toastSuccess("This request is reopened!");
    refetch();
  };

  const handleDownloadDocuments = () => {
    postDownloadRequest(`/api/customerrequests/${customerRequestKey}/download`);
  };

  const handleEdit = () => {
    history.push(`/requests/${customerRequestKey}/edit`);
  };

  const handleConfirmCloseRequest = async (result) => {
    setShowConfirmDialog(false);
    if (result === "Yes") {
      await closeRequest();
    }
  };

  const handleCloseRequest = async () => {
    if (requestDetails.docRequests.some((d) => d.docFiles.length <= 0)) {
      setShowConfirmDialog(true);
      return;
    }
    await closeRequest();
  };

  const handleReopenRequest = async () => {
    await reopenRequest();
  };

  useEffect(() => {
    if (status === "loaded") {
      {
        setRequestDetails(data);
        setHasDocuments(data.docFilesCount > 0);
        setAccountName(data.accounts[0]?.name);
      }
    }
  }, [status, data]);

  useEffect(() => {
    if (status === "error") {
      setError({ asage: fetchRequestError });
    }
  }, [status, fetchRequestError]);

  useEffect(() => {
    if (!!error) {
      setShowErrorModal(true);
    }
  }, [error]);

  return (
    <DocuPointLayout backgroundColor="bg-gray-100">
      {status === "loading" || !requestDetails ? (
        <Placeholder />
      ) : status === "error" ? (
        <div>
          <div className="flex justify-center">
            <ErrorFallback error={error} />
          </div>
          <div className="flex justify-center mt-10">
            <div>
              <ThemedButton onClick={reload}>
                <RefreshIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                Try again
              </ThemedButton>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex w-full">
            <div className="grow">
              <BreadCrumb_Requests_Request
                customerRequestKey={customerRequestKey}
                accountName={accountName}
              />
            </div>
            <div className="flex-none">
              <div className="flex justify-self-end items-center">
                <ThemedButton
                  fitted={true}
                  onClick={handleDownloadDocuments}
                  type="submit"
                  disabled={!hasDocuments}
                >
                  Download All
                </ThemedButton>
              </div>
            </div>
          </div>

          <Tab.Group as="div" className="mt-2">
            <div className="border-b border-gray-200">
              <Tab.List className="-mb-px flex px-4 space-x-8 w-3/12">
                {screenTabs.map((tab) => (
                  <Tab
                    key={tab.key}
                    className={({ selected }) =>
                      classNames(
                        selected ? "selected" : "",
                        "pageTab flex-1 whitespace-nowrap py-2 px-1 border-b-2 text-lg font-medium focus-visible:outline-none"
                      )
                    }
                  >
                    {tab.name}
                  </Tab>
                ))}
              </Tab.List>
            </div>
            <Tab.Panels as={Fragment}>
              <Tab.Panel>
                <RequestDetails data={requestDetails} />
              </Tab.Panel>
              <Tab.Panel>
                <Submissions
                  requestKey={requestDetails.key}
                  setError={setError}
                />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      )}
      {showErrorModal && (
        <ErrorModal
          show={showErrorModal}
          errorMessage={error?.message}
          onClose={() => setShowErrorModal(false)}
        ></ErrorModal>
      )}
      <ConfirmModal
        show={showConfirmDialog}
        actions={[{ text: "No" }, { text: "Yes", primary: true }]}
        title="Please confirm"
        onAction={handleConfirmCloseRequest}
      >
        This request has active document requests, are you sure want to close
        the request?
      </ConfirmModal>
    </DocuPointLayout>
  );
}

export { RequestScreen };
