import { WebAuth } from "auth0-js";
import { getLoginDetails } from "../utils/utils";

const authInfo = {
  clientId: null,
  domain: null,
  audience: null,
  authority: null,
  getAuthenticationHeaders: function () {
    const loginDetails = getLoginDetails();
    const headers = {
      Authorization: `Bearer ${loginDetails.bearer}`,
      DocuPointAccountKey: loginDetails.accountKey,
    };

    if (loginDetails.tenantInfo) {
      headers["tenant-key"] = loginDetails.tenantInfo.key;
    }
    return headers;
  },
};

const getWebAuth = () => {
  if (!authInfo.domain) {
    return null;
  }
  return new WebAuth({
    clientID: authInfo.clientId,
    domain: authInfo.domain,
    redirectUri: `${window.location.origin}/authresult`,
    audience: authInfo.audience,
    responseType: "token",
  });
};

const getAuthConfig = async (tenantKey) => {
  let resp = await fetch("/authinfo?tenantKey=" + tenantKey);
  let data = await resp.json();

  authInfo.audience = data.audience;
  authInfo.authority = data.authority;
  authInfo.clientId = data.clientId;
  authInfo.domain = data.domain;
};

export { authInfo, getWebAuth, getAuthConfig };
