import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  CancelButton,
  ThemedButton,
} from "../../components/buttons/themedButton";
import { DocuPointLayout } from "../../components/docupointLayout";
import { postJson } from "../../utils/api-client";
import { ErrorModal } from "../../components/errorModal";
import { useState } from "react";
import { GlobalHeader } from "../global/globalHeader";
import { Validator } from "../../components/validator";

function DomainAddScreen() {
  const [domainName, setDomainName] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [respMessage, setRespMessage] = useState(null);

  const history = useHistory();

  const onDomainNameChange = (e) => {
    setDomainName(e.target.value);
  };

  const onSave = async () => {
    const resp = await postJson(
      "/api/domainsettings",
      { name: domainName },
      "POST"
    );

    if (resp.ok) {
      history.push("/global/domains");
      // Redirect to the domain list screen
    } else {
      setRespMessage(await resp.text());
      setShowErrorModal(true);
      // Show error message
    }
  };

  const onCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  return (
    <>
      <DocuPointLayout>
        <div className="bg-gray-100 p-4">
          <div className="formSection">
            <div className="formSectionLeft">
              <div className="px-4 sm:px-0">
                <h3 className="formSectionHeader">Add new Domain</h3>
              </div>
            </div>

            <div className="formSectionRight">
              <div className="formSectionRightPanel">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label htmlFor="name" className="formLabel">
                      Domain Name
                    </label>
                    <input
                      type="text"
                      value={domainName}
                      name="domainName"
                      onChange={onDomainNameChange}
                    />
                  </div>
                </div>
                <div className="flex gap-4 mt-4">
                  <CancelButton
                    fitted={true}
                    onClick={() => history.push("/global/domains")}
                  >
                    Back to list
                  </CancelButton>
                  <ThemedButton fitted={true} onClick={onSave}>
                    Save
                  </ThemedButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocuPointLayout>
      <ErrorModal
        show={showErrorModal}
        errorMessage={respMessage}
        onClose={onCloseErrorModal}
      ></ErrorModal>
    </>
  );
}

export { DomainAddScreen };
