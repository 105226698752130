import React, { useEffect, useState } from "react";
import { ThemedButton } from "../../components/buttons/themedButton";
import { Modal } from "../../components/modal";
import { Popover } from "@headlessui/react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { PlusIcon } from "@heroicons/react/outline";
import { authInfo } from "../../components/authInfo";

function DomainListScreen() {
  const [domains, setDomains] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      const url = `/api/domainsettings`;
      const response = await fetch(url, {
        headers: authInfo.getAuthenticationHeaders(),
      });

      if (response.ok) {
        const data = await response.json();
        setDomains(data);
      }
    };

    loadData();
  }, []);

  return (
    <>
      <div className="flex w-auto">
        <Link to="domains/add" className="ml-2">
          <ThemedButton>
            <PlusIcon className="w-5 mr-2" />
            Add Domain
          </ThemedButton>
        </Link>
      </div>
      <table className="dataTable w-full">
        <thead>
          <tr>
            <th className="text-left">Domain Name</th>
            <th className="text-left">Active Tenants</th>
          </tr>
        </thead>
        <tbody>
          {domains.map((itm, idx) => {
            return (
              <tr key={itm.id}>
                <td>{itm.name}</td>
                <td>{itm.tenants}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export { DomainListScreen };
