import React from "react";
import { GlobalHeader } from "./globalHeader";
import { DocuPointLayout } from "../../components/docupointLayout";
import { DomainListScreen } from "../domains/domainListScreen";
function GlobalDomains() {
  return (
    <DocuPointLayout>
      <GlobalHeader location="Domains" />
      <DomainListScreen />
    </DocuPointLayout>
  );
}

export { GlobalDomains };
