import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./components/errorFallback";
import { HomeScreen } from "./screens/homeScreen";
import { AccountScreen } from "./screens/account";
import { ProtectedRoute } from "./components/protectedRoute";
import { ProfileScreen } from "./screens/profile/profileScreen";
import { UploadModal } from "./screens/upload";
import { ThankYouScreen } from "./screens/thankYou/thankYou";
import { DocuPointLayout } from "./components/docupointLayout";
import { NotFoundScreen } from "./screens/error/404";
import {
  MissingTenant,
  NoActiveAccounts,
  InvalidKey,
} from "./screens/error/errorScreens";
import {
  LoginCodeScreen,
  AuthorizeScreen,
  LoginScreen,
  LogoutScreen,
} from "./screens/login";
import { AppLoginScreen } from "./screens/login/appLoginScreen";
import { SetPasswordScreen } from "./screens/login/setPasswordScreen";
import { InvitationScreen } from "./screens/login/invitationScreen";
import { RequestsIndex } from "./screens/requests/requestsIndex";
import { TenantsScreen } from "./screens/tenants/tenantsScreen";
import { TenantSettingsScreen } from "./screens/tenants/tenantSettingsScreen";
import { GlobalSettingsScreen } from "./screens/global/globalSettingsScreen";
import { GlobalRoute } from "./screens/global/globalRoute";
import { GlobalUsersScreen } from "./screens/global/globalUsers";
import { TenantUsersScreen } from "./screens/tenants/tenantUsers";
import { AppUserScreen } from "./screens/users/appUserScreen";
import { GlobalAppUserScreen } from "./screens/global/globalAppUser";
import { TenantAppUserScreen } from "./screens/tenants/tenantAppUser";
import { ProfileError } from "./screens/error/errorScreens";
import { TenantTemplates } from "./screens/tenants/tenantTemplates";
import { TemplateDetails } from "./screens/tenants/templates/templateDetails";
import { configVariables } from "./variables/config-variables";
import { AddTenantScreen } from "./screens/tenants/addTenantScreen";
import { unregisterServiceWorkers } from "./utils/utils";
import * as Constants from "./constants";
import { SessionExpired } from "./screens/session-expired/sessionExpired";
import { GlobalDomains } from "./screens/global/globalDomains";
import { DomainAddScreen } from "./screens/domains/domainAddScreen";

const App = () => {
  console.log("build version #", configVariables.buildVersion);

  unregisterServiceWorkers();

  const location = useLocation();

  window.addEventListener("storage", (e) => {
    // skip if either local storage value is empty
    if (!e.newValue || !e.oldValue) {
      return;
    }
    const oldValue = JSON.parse(e?.oldValue ?? "{}");
    const newValue = JSON.parse(e?.newValue ?? "{}");

    if (newValue.accountKey !== oldValue.accountKey) {
      window.location.href = "/session-expired";
    }
  });

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Switch key={location.pathname}>
        <Route exact path="/">
          {" "}
          <HomeScreen />{" "}
        </Route>
        <Route path="/404">
          <NotFoundScreen />
        </Route>
        <Route path="/tenant-error">
          <MissingTenant />
        </Route>
        <Route path="/invalid-key">
          <InvalidKey />
        </Route>
        <Route path="/no-account">
          <NoActiveAccounts />
        </Route>
        <Route path="/profile-error">
          <ProfileError />
        </Route>
        <Route path="/login">
          {" "}
          <LoginScreen />{" "}
        </Route>
        <Route path="/invitation/:key">
          {" "}
          <InvitationScreen />{" "}
        </Route>
        <Route path="/setPassword">
          <SetPasswordScreen />{" "}
        </Route>
        <Route path="/applogin">
          {" "}
          <AppLoginScreen />{" "}
        </Route>
        <Route path="/loggedout">
          {" "}
          <LogoutScreen />{" "}
        </Route>
        <Route path="/session-expired">
          {" "}
          <SessionExpired />{" "}
        </Route>
        <Route path="/login-code">
          <LoginCodeScreen />
        </Route>
        <Route path="/authresult">
          <AuthorizeScreen />
        </Route>
        <ProtectedRoute
          path="/account"
          requiredRoles={[Constants.ROLES_ACCOUNT]}
        >
          <DocuPointLayout>
            <AccountScreen />
          </DocuPointLayout>
        </ProtectedRoute>
        <ProtectedRoute
          path="/thankyou"
          requiredRoles={[Constants.ROLES_ACCOUNT]}
        >
          <DocuPointLayout>
            <ThankYouScreen />
          </DocuPointLayout>
        </ProtectedRoute>
        <ProtectedRoute
          path="/profile"
          requiredRoles={[
            Constants.ROLES_ACTIVE_USER,
            Constants.ROLES_APP_USER,
          ]}
        >
          <ProfileScreen />
        </ProtectedRoute>
        <ProtectedRoute
          path="/requests"
          requiredRoles={[
            Constants.ROLES_ACTIVE_USER,
            Constants.ROLES_APP_USER,
          ]}
        >
          <RequestsIndex />
        </ProtectedRoute>
        <ProtectedRoute
          path="/tenants/add"
          requiredRoles={[Constants.ROLES_GLOBAL_ADMIN]}
        >
          <AddTenantScreen />
        </ProtectedRoute>
        <ProtectedRoute
          path="/tenants"
          requiredRoles={[
            Constants.ROLES_ACTIVE_USER,
            Constants.ROLES_APP_USER,
          ]}
        >
          <TenantsScreen />
        </ProtectedRoute>
        <ProtectedRoute
          path="/settings"
          requiredRoles={[Constants.ROLES_TENANT_ADMIN]}
        >
          <TenantSettingsScreen />
        </ProtectedRoute>
        <ProtectedRoute
          path="/users/add"
          requiredRoles={[Constants.ROLES_TENANT_ADMIN]}
        >
          <TenantAppUserScreen />
        </ProtectedRoute>
        <ProtectedRoute
          path="/users/:appUserKey"
          requiredRoles={[Constants.ROLES_TENANT_ADMIN]}
        >
          <TenantAppUserScreen />
        </ProtectedRoute>
        <ProtectedRoute
          path="/users"
          requiredRoles={[Constants.ROLES_TENANT_ADMIN]}
        >
          <TenantUsersScreen />
        </ProtectedRoute>
        <ProtectedRoute
          path="/templates"
          requiredRoles={[Constants.ROLES_TENANT_ADMIN]}
        >
          <TenantTemplates />
        </ProtectedRoute>
        <ProtectedRoute
          path="/global/settings"
          requiredRoles={[Constants.ROLES_GLOBAL_ADMIN]}
        >
          <GlobalRoute>
            <GlobalSettingsScreen />
          </GlobalRoute>
        </ProtectedRoute>
        <ProtectedRoute
          path="/global/users/add"
          requiredRoles={[Constants.ROLES_GLOBAL_ADMIN]}
        >
          <GlobalAppUserScreen />
        </ProtectedRoute>
        <ProtectedRoute
          path="/global/users/:appUserKey"
          requiredRoles={[Constants.ROLES_GLOBAL_ADMIN]}
        >
          <GlobalAppUserScreen />
        </ProtectedRoute>
        <ProtectedRoute
          path="/global/users"
          requiredRoles={[Constants.ROLES_GLOBAL_ADMIN]}
        >
          <GlobalRoute>
            <GlobalUsersScreen />
          </GlobalRoute>
        </ProtectedRoute>{" "}
        <ProtectedRoute
          path="/global/domains/add"
          requiredRoles={[Constants.ROLES_GLOBAL_ADMIN]}
        >
          <DomainAddScreen />
        </ProtectedRoute>
        <ProtectedRoute
          path="/global/domains"
          requiredRoles={[Constants.ROLES_GLOBAL_ADMIN]}
        >
          <GlobalRoute>
            <GlobalDomains />
          </GlobalRoute>
        </ProtectedRoute>
        <Route path="*">
          <NotFoundScreen />
        </Route>
      </Switch>
    </ErrorBoundary>
  );
};

export { App };
