import { useHistory } from "react-router-dom";
import { getWebAuth } from "../../components/authInfo";
import { postJson } from "../../utils/api-client";
import { getLoginDetails, saveLoginDetails } from "../../utils/utils";

const startConnectIdentity = (
  emailAddress,
  appUserKey,
  loginConfig,
  history
) => {
  const state = {
    mode: "connect",
    appUserKey: appUserKey,
  };

  cacheLoginDetails(emailAddress, loginConfig);

  if (loginConfig.identityProviderType == "Auth0DB") {
    history.push("/setPassword", state);
  } else {
    doDocuPointLogin(emailAddress, loginConfig, history, state);
  }
};

const startDocuPointLogin = (emailAddress, loginConfig, history) => {
  cacheLoginDetails(emailAddress, loginConfig);
  doDocuPointLogin(emailAddress, loginConfig, history, null);
};

const startDatabaseLogin = (loginStateJson) => {
  const loginDetails = getLoginDetails();
  getWebAuth().authorize({
    connection: "Username-Password-Authentication",
    login_hint: loginDetails.email,
    state: loginStateJson,
  });
};

const cacheLoginDetails = (emailAddress, loginConfig) => {
  const loginDetails = {
    userType: "AppUser",
    accountKey: "AppUser",
    authenticationType: "email",
    email: emailAddress,
    identityProviderName: loginConfig.identityProviderName,
    identityProviderType: loginConfig.identityProviderType,
    bearer: null,
    hasLoggedIn: false,
    tenantKey: loginConfig.tenantKey,
    tenantInfo: loginConfig.tenantInfo,
  };

  saveLoginDetails(loginDetails);
};

const doDocuPointLogin = (emailAddress, loginConfig, history, loginState) => {
  const loginStateJson = loginState ? JSON.stringify(loginState) : null;

  switch (loginConfig.identityProviderType) {
    case "Auth0Passwordless":
      history.push("/login-code", loginStateJson);
      break;
    case "Auth0DB":
      startDatabaseLogin();
      break;
    case "Auth0AAD":
      getWebAuth().authorize({
        connection: "AAD-BankPoint",
        login_hint: emailAddress,
        state: loginStateJson,
      });
      break;
  }
};

const connectIdentity = async (appUserKey, identityProviderName) => {
  const data = {
    appUserKey: appUserKey,
    identityProviderName: identityProviderName,
  };

  const resp = await postJson("/api/identities", data);

  return resp.ok;
};

export {
  startConnectIdentity,
  startDocuPointLogin,
  connectIdentity,
  startDatabaseLogin,
};
