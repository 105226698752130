import { authInfo, getWebAuth } from "../components/authInfo";
import { revokeToken } from "./api-client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const bankpointLogo = "/logo-nopad@3x-8.png";

const threeDots = (value, max) => {
  if (!value || value.length < max) {
    return value;
  }

  return value.substring(0, max) + "...";
};

const getFormattedDate = (date) => {
  return date
    ? date.toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    : "";
};

const saveLoginDetails = (data) => {
  const value = data ? JSON.stringify(data) : null;
  window.localStorage.setItem("loginDetails", value);
};

const getLoginDetails = () => {
  var json = window.localStorage.getItem("loginDetails");
  if (json) {
    return JSON.parse(json);
  }
  return null;
};

const setTenantContext = async (tenant) => {
  const loginDetails = getLoginDetails();
  loginDetails.tenantInfo = tenant;
  saveLoginDetails(loginDetails);
  setBrowserBranding(tenant);
  await getProfile(true);
};

const getProfile = async (forceRefresh) => {
  let profile = null;
  if (!forceRefresh) {
    profile = window.sessionStorage.getItem("profile");
    if (profile) {
      return JSON.parse(profile);
    }
  }
  const response = await fetch("/api/profile", {
    headers: authInfo.getAuthenticationHeaders(),
  });
  if (response.ok) {
    profile = await response.json();
    window.sessionStorage.setItem("profile", JSON.stringify(profile));
    return profile;
  } else {
    const message = await response.text();
    console.log(`Error loading profile data: ${message}`);
    return null;
  }
};

const clearProfile = () => {
  window.sessionStorage.removeItem("profile");
};

const isInRole = (profile, role) => {
  if (!profile) {
    return false;
  }
  return profile.roles && profile.roles.indexOf(role) >= 0;
};

const getRoles = async () => {
  const response = await fetch("/api/security", {
    headers: authInfo.getAuthenticationHeaders(),
  });
  if (response.ok) {
    const data = await response.json();
    return data.roles;
  } else {
    const message = await response.text();
    console.log(`Error loading security data: ${message}`);
    return null;
  }
};

const startPasswordlessLogin = (cb) => {
  const loginDetails = getLoginDetails();
  const authenticationOptions = {
    connection: loginDetails.authenticationType,
    send: "code",
    authParams: {
      state: "invitation",
    },
  };

  switch (loginDetails.authenticationType) {
    case "email":
      authenticationOptions.email = loginDetails.email;
      break;
    case "sms":
      authenticationOptions.phoneNumber = loginDetails.phoneNumber;
      break;
  }
  getWebAuth().passwordlessStart(authenticationOptions, cb);
};

const startUniversalLogin = (connection) => {
  getWebAuth().authorize({
    connection: "Username-Password-Authentication",
    //connection: "AAD-BankPoint",
  });
};

const logout = () => {
  const webAuth = getWebAuth();
  const loginDetails = getLoginDetails();
  revokeToken();
  //Forget the appuser details, but keep the details for Account since it contains tenant info
  if (loginDetails?.userType == "AppUser") {
    saveLoginDetails(null);
    clearProfile();
  }
  webAuth.logout({
    returnTo: `${window.location.origin}/loggedout`,
  });
  removeCookies();
};

const removeCookies = () => {
  var res = document.cookie;
  var multiple = res.split(";");
  for (var i = 0; i < multiple.length; i++) {
    var key = multiple[i].split("=");
    document.cookie = key[0] + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
  }
};

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};

const toastSuccess = (message) => {
  toast.success(message, toastOptions);
};

const toastError = (message) => {
  toast.error(message, toastOptions);
};

const checkRequiredFields = function (fieldValues, requiredFields, errorsList) {
  for (let i = 0; i < requiredFields.length; i++) {
    const field = requiredFields[i];
    if (!fieldValues[field]) {
      errorsList[field] = "This field is required";
    }
  }
};

const isResponseUnauthorized = (response) => {
  return response.status == 401 || response.status == 403;
};

const getWebResponseError = (response) => {
  let errorMessage =
    "We're sorry. There seems to be an issue loading this page.";
  if (isResponseUnauthorized(response)) {
    errorMessage = "You do not have permission to this content.";
  }
  return new Error(errorMessage);
};

const toQueryString = (params) => {
  return Object.keys(params)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    })
    .join("&");
};

const valueOrEmpty = (value) => {
  return value ? value : "";
};

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const unregisterServiceWorkers = () => {
  //Remove any service worker which still exists in the browser. We've disabled service workers, but some browsers
  //may still have an instance of one.
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
      if (registrations.length > 0) {
        window.location.reload();
      }
    });
  }
};

const getRecentCompletionDate = () => {
  let recentDate = new Date();
  recentDate.setDate(recentDate.getDate() - 30);
  return new Date(
    recentDate.getFullYear(),
    recentDate.getMonth(),
    recentDate.getDate()
  );
};

const setBrowserBranding = (tenantInfo) => {
  if (tenantInfo?.showBankPointBranding === false) {
    document.title = `${tenantInfo?.name} Document Portal`;
  }

  if (tenantInfo?.hasFavIcon === true) {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    link.href = `/favicon?tenantkey=${tenantInfo.key}`;
  }
};

export {
  bankpointLogo,
  saveLoginDetails,
  getLoginDetails,
  startPasswordlessLogin,
  startUniversalLogin,
  threeDots,
  logout,
  toastSuccess,
  toastError,
  checkRequiredFields,
  getWebResponseError,
  getFormattedDate,
  toQueryString,
  getProfile,
  clearProfile,
  isResponseUnauthorized,
  valueOrEmpty,
  classNames,
  isInRole,
  setTenantContext,
  unregisterServiceWorkers,
  getRoles,
  getRecentCompletionDate,
  setBrowserBranding,
};
